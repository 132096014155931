import React from 'react'

import Layout from '../components/Layouts/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <h1>NOT FOUND</h1>
    <p>The page you are looking for doesn't seem to exist.</p>
  </Layout>
)

export default NotFoundPage
